import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.alulatechnologies.vivascore.longevity',
  appName: 'Longevity App',
  webDir: 'www',
  loggingBehavior:'production',

  plugins: {
    SplashScreen: {
      launchAutoHide: true,
      launchShowDuration: 10000,
      androidSplashResourceName: "splash",
      androidScaleType: "CENTER_CROP",    
      backgroundColor: "#27cfa7",
      splashFullScreen: true,
      splashImmersive: true,
      useDialog : false
    },
  },

  ios: {
    scheme:'longevity',
    handleApplicationNotifications: false    
  },

  android:{
    flavor:'longevity'
  }
};

export default config;
